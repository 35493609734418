<template>
    <div class="overflow-y-hidden md:pb-10 antialiased">
        <div class="mx-auto max-w-[1200px] flex flex-col md:gap-10 px-0 md:px-16 md:py-6 h-full md:h-dvh">
            <div class="flex justify-between items-end px-6 py-4 md:p-10 md:rounded-3xl min-h-[120px] md:min-h-[200px] header-bg">
                <h1 class="heading-2 text-[#555BA2]">Your Chat History</h1>
                <BaseButton theme="startChat" @click="openUrl($startChatUrl, { chat_source: CHATSOURCE.PAST_CONVERSATIONS })">
                    <CoachingModeMarble class="w-6 h-6" />Start Chat
                </BaseButton>
            </div>
            <div v-bind="containerProps" class="md:border-2 md:border-[#E8E8E8] md:rounded-3xl bg-white pb-6 grow">
                <div v-bind="wrapperProps">
                    <button
                        v-for="{ data, index } in list"
                        :key="index"
                        type="button"
                        class="border-b-2 border-[#E8E8E8] p-6 h-32 flex justify-between items-center gap-4 w-full transition-all group hover:bg-[#F7F7F7]"
                        @click="openUrl(data.return_url, { chat_source: CHATSOURCE.PAST_CONVERSATIONS })">
                        <div class="grow text-left">
                            <p class="body-1 text-[#8C8C8C] mb-2">{{ convertToDateTime(data.updated_at).toFormat("LLL dd, yyyy") }}</p>
                            <p class="chat-1">{{ truncate(data.title, { length: TRUNCATE_LENGTH, omission: "..." }) }}</p>
                        </div>
                        <BasePopover v-model:open="isMenuOpen[index]" as-child side="left" :side-offset="10">
                            <template #trigger>
                                <button class="p-2 rounded-full hover:bg-[#555BA21F] w-10 h-10" :class="{ 'bg-[#555BA21F]': isMenuOpen[index] }" @click.stop>
                                    <i class="bi bi-three-dots text-2xl/none" />
                                </button>
                            </template>
                            <template #content>
                                <div class="flex flex-col gap-4 py-2">
                                    <button
                                        class="flex items-center gap-2 px-4 group hover:text-red-600 transition-colors"
                                        @click="handleDeletePastConversation(data.hashid, index)">
                                        <i class="bi bi-trash text-xl/none text-[#8C8C8C] group-hover:text-red-600 transition-colors" /><span class="body-1">Delete</span>
                                    </button>
                                </div>
                            </template>
                        </BasePopover>
                        <div class="text-[#555BA2] body-1 whitespace-nowrap"><span class="hidden md:inline">Start Chat</span><i class="bi bi-arrow-right icon-bold ml-1.5" /></div>
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import LeftSidebarLayout from "~vue/layouts/LeftSidebarLayout.vue";
export default { layout: LeftSidebarLayout };
</script>

<script setup>
import { convertToDateTime } from "/js/dateUtils";
import { router } from "@inertiajs/vue3";
import { useVirtualList } from "@vueuse/core";
import { useFetch } from "~vue/api";
import BaseButton from "~vue/components/BaseButton.vue";
import BasePopover from "~vue/components/BasePopover.vue";
import CoachingModeMarble from "~vue/components/navigation/CoachingModeMarble.vue";
import { openUrl } from "~vue/utils";
import { CHATSOURCE } from "~vue/utils/constants";
import { logError, logUserInteraction } from "~vue/utils/logUtils";
import truncate from "lodash/truncate";
import { computed, ref } from "vue";

const TRUNCATE_LENGTH = 120;
const isMenuOpen = ref([]);

const { past_conversations } = defineProps({
    past_conversations: { type: Array, required: true },
});

const pastConversations = computed(() => past_conversations);

const { list, containerProps, wrapperProps } = useVirtualList(pastConversations, {
    overscan: 10,
    itemHeight: 126,
});

const activeHashId = ref(null);
const deleteUrl = computed(() => `/accounts/coaching_session/${activeHashId.value}`);

const { execute: deletePastConversation, onFetchError, onFetchFinally } = useFetch(deleteUrl, { immediate: false }).delete();
const handleDeletePastConversation = async (hashid, index) => {
    isMenuOpen.value[index] = false;
    activeHashId.value = hashid;
    const delete_confirmation = confirm("Are you sure you would like to delete this conversation?");
    logUserInteraction("delete_conversation", { hashid }, activeHashId.value);
    if (!delete_confirmation) {
        return;
    }
    await deletePastConversation();
    router.reload({ only: ["past_conversations"] });
};

onFetchError((error) => {
    logError(error);
});

onFetchFinally(() => {
    activeHashId.value = null;
});
</script>

<style scoped>
.header-bg {
    background: linear-gradient(270deg, #f9f6f1 16%, #e8e9fd 76%);
}
</style>
